import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, QueryList, ViewChild, ViewChildren, SimpleChanges } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { SharedService } from '../../services/shared.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SOCIAL_LINKS } from '../../constant/app.constant';
import { MultilingualTranslationService } from '../../services/multilingual-translation.service';
import { ToastrService } from 'ngx-toastr';
import { EventService } from '../../services/event.service';
import { WsEvents } from '../../services/events';

@Component({
  selector: 'app-nav-slider',
  templateUrl: './nav-slider.component.html',
  styleUrls: ['./nav-slider.component.scss'],
})
export class NavSliderComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() navSlideData: { header: any, dataList: any[], seeAllBtn?: any, videoCategory?: string, localDataList?:any[], galleryList?:any[] };
  @Input() courseListLoad : boolean =false;
  @ViewChild(CarouselComponent, null) carousel: CarouselComponent;
  Options: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    autoplay: true,
    lazyLoad: false,
    autoplaySpeed: 2000,
    margin: 24,
    navSpeed: 200,
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    autoHeight: true,
    nav: true,
    responsive: {
      0: { items: 1, margin: 10 },
      400: { items: 2 },
      600: { items: 2 },
      991: { items: 3 },
      1199: { items: 3 }
    }
  };
  screenWidth: number;
  twitterEmbedCode: SafeHtml;
  socialLinks = SOCIAL_LINKS;
  currentLang: string;
  currentWhatisActiveVideoid: string;
  currentVideoGallaryPlyerId: string;
  isSkeletonLoader:boolean = false;
  dataSlider:any={
    isFeatureCourse :false,
    isVideoGallery:false,
    isWhatIs:false,
    isNewsRoom:false,
    isPhotoGallery:false,
    isTestimonial:false,
    isBannerInner:false
  }

  isDownloadComplete: boolean = false;
  @ViewChildren('testimonialVideo') allTestimonialVideos: QueryList<ElementRef>;

  currentDownloadIndex: number = -1;
  activeTestimonialIndex: number | undefined;
  
  constructor(private sharedSvc: SharedService, public sanitizer: DomSanitizer, private multilingual: MultilingualTranslationService, private toastr: ToastrService,private eventSvc: EventService) {
    this.multilingual.languageChange$.subscribe(lang => {
      if (lang) this.currentLang = lang;
    })
  }
  ngOnInit() {
    if(Object.keys(this.navSlideData).length !==0){
      if(this.navSlideData.header.type =='what-is' && this.navSlideData.dataList.length!==0){
         this.dataSlider.isWhatIs = true;
      }
      if(this.navSlideData.header.type =='news-room' && (this.navSlideData.dataList.length!==0 || this.navSlideData.localDataList.length!==0)){
           this.dataSlider.isNewsRoom = true;  
      }
      if(this.navSlideData.header.type == 'testimonials' && this.navSlideData.dataList.length!==0){
           this.dataSlider.isTestimonial = true;   
      }

      if(this.navSlideData.header.type =='banner-inner' && this.navSlideData.dataList.length!==0){
         this.dataSlider.isBannerInner = true;    
      }
      if(this.navSlideData.header.type =='photo-gallary' && this.navSlideData.galleryList.length!==0){
         this.dataSlider.isPhotoGallery = true;
      }
      if(this.navSlideData.header.type =='video-gallery'){
        if(this.navSlideData.dataList.length!==0){
          this.dataSlider.isVideoGallery = true;
        }
      } 
      if(this.navSlideData.header.type =='featured-courses'){ 
          if(this.navSlideData.dataList.length!==0){
            this.dataSlider.isFeatureCourse = true;
          }
       }

    }

   

    this.screenWidth = this.sharedSvc.screenWidth;
    if (this.navSlideData && this.navSlideData['header'] && this.navSlideData['header']['type']) {
      if (this.navSlideData['header']['type'] === 'banner-inner') {
        this.Options = { ...this.Options, nav: false, margin: 0, lazyLoad: true, navText: ['', ''], responsive: { 0: { items: 1 }, 400: { items: 1 }, 600: { items: 1 }, 991: { items: 1 }, 1199: { items: 1 } }, autoWidth: true }
        if (this.navSlideData.dataList.length < 2) {
          this.Options = { ...this.Options, dots: false, nav: false, autoplay: false, navText: ['', ''], mouseDrag: false, touchDrag: false, loop: false, }
        }

      }
      else if (this.navSlideData['header']['type'] === 'video-gallery') {
        this.Options = { ...this.Options, navSpeed: 500, autoplay: false, loop: false, autoplayHoverPause: true, rewind: false, responsive: { 0: { items: 1, autoplay: false }, 400: { items: 1, autoplay: false }, 600: { items: 2, autoplay: false }, 991: { items: 3, autoplay: false }, } }

      }
      else if (this.navSlideData['header']['type'] === 'featured-courses') {
        this.Options = { ...this.Options, dots: false, mouseDrag: false, responsive: { 0: { items: 1 }, 576: { items: 2 }, 756: { items: 3 }, 992: { items: 4 } } }
        if (this.navSlideData.dataList.length < 5) {
          // this.Options = { ...this.Options, dots: false, nav: true, autoplay: true,  }
        }
      }
      else if (this.navSlideData['header']['type'] === 'what-is') {
        if (this.navSlideData.dataList.length < 4 && this.sharedSvc.screenWidth > 576) {
          this.Options = {
            ...this.Options, dots: false, nav: true, loop: false, autoplay: false, mouseDrag: false, touchDrag: true, responsive: { 0: { items: 1 }, 576: { items: 2 }, 756: { items: 3 }, 991: { items: 2, stagePadding: 200 }, 1200: { items: 2,stagePadding: 200 }, }
          }
        }
      }
      else if (this.navSlideData['header']['type'] === 'news-room') {
        this.Options = { ...this.Options, dots: false, autoplaySpeed: 5000, autoplayHoverPause: true, responsive: 
          { 
            0: { items: 1.5}, 
          
            400: { items: 2 }, 
          
            576: { items: 3 }, 
          
            991: { items: 5 } } }
      }
      else if (this.navSlideData['header']['type'] === 'content-providers') {
        this.Options = {
          ...this.Options, dots: false, nav: false, navText: ['>', '',], autoHeight: false, responsive: {
            0: { items: 2 },
            400: { items: 3 },
            600: { items: 5 },
            767: { items: 6 },
            991: { items: 7 },
            1199: { items: 7 }
          }
        }
      }
      else if (this.navSlideData['header']['type'] === 'testimonials') {
        this.Options = {
          ...this.Options, autoHeight: false, autoWidth: false, dots: false, margin:0, responsive: {
            0: { items: 1, autoplay: false },
            400: { items: 1, autoplay: false },
            600: { items: 1, autoplay: false },
            1200: { items: 1, stagePadding: 0, autoplay: false },
            1300: { items: 1, stagePadding: 250, autoplay: false },
            // 2400: { items: 3, stagePadding: 200, autoplay: false },
            // 2700: { items: 4, stagePadding: 200, autoplay: false },
            // 4000: { items: 5, stagePadding: 400, autoplay: false },
            // 7000: { items: 5, stagePadding: 400, autoplay: false },
          },

        }
      }
      else if (this.navSlideData['header']['type'] === 'about-us') {
        this.Options = {
          ...this.Options, responsive: {
            0: { items: 1 },
            400: { items: 1 },
            600: { items: 1 },
            767: { items: 1 },
            991: { items: 1 },
            1199: { items: 1 }
          },

        }
      }
      else if (this.navSlideData['header']['type'] === 'social-hub') {
        this.Options = {
          ...this.Options, loop: false, rewind: true, autoplaySpeed: 10000, responsive: {
            0: { items: 1, autoplay: false },
            400: { items: 1, autoplay: false },
            600: { items: 1, autoplay: false },
            767: { items: 1, autoplay: false },
            991: { items: 1 },
            1199: { items: 1 }         
          },

        }
      }
      else if (this.navSlideData['header']['type'] === 'organisationsPartners') {
        this.Options = {
          ...this.Options, autoplaySpeed: 3000, margin: 0, dots: false, nav: false, responsive: {
            0: { items: 1, },
            400: { items: 1, },
            600: { items: 2, stagePadding: 100 },
            991: { items: 3, stagePadding: 100 },
            1199: { items: 5, stagePadding: 100 }
          },

        }
      }
      else if (this.navSlideData['header']['type'] === 'photo-gallary') {
        this.Options = {
          ...this.Options,autoplay: true, autoplaySpeed: 500,
          mouseDrag: true, margin: 24, dots: false, nav: false, responsive: {
            0: {
              items: 1.05
            },
            360: {
              items: 1.15
            },
            375: {
              items: 1.25
            },
            400: {
              items: 1.25
            },
            500: {
              items: 1.5
            },
            600: {
              items: 1.75
            },
            800: {
              items: 2
            },
            900: {
              items: 2.25
            },
        
            1024: {
              items: 2.5
            },
            1200: {
              items: 3.05
            },
            1366: {
              items: 3.5
            },
            1440: {
              items: 3.65
            },
            1600: {
              items: 4.15
            },
            1680: {
              items: 4
            },
            1800: {
              items: 4.5
            },
            1920: {
              items: 4.75
            },
            2000: {
              items: 5
            },
            2200: {
              items: 5.65
            },
            2400: {
              items: 6
            },
            2700: {
              items: 6.65
            },
            3600: {
              items: 8.75
            },
            4000: {
              items: 12.75
            },
            7000: {
              items: 16
            }
          }
        }
      }

    }

    this.twitterEmbedCode = this.sanitizer.bypassSecurityTrustHtml(`
    <blockquote class="twitter-tweet">
      <p lang="en" dir="ltr">If the plan doesn't work, change the plan but never the goal. <a href="https://t.co/4jJRGkC8l1">pic.twitter.com/4jJRGkC8l1</a></p>
      &mdash; Nazir Hussain (@nazirhssn0) <a href="https://twitter.com/nazirhssn0/status/1760314672149082386?ref_src=twsrc%5Etfw">February 21, 2024</a>
    </blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  `);

  }
  slideNext() { this.carousel.next(); }
  slidePrevious() { this.carousel.prev(); }
  
  ngOnChanges(changes: SimpleChanges){
    if(Object.keys(this.navSlideData).length !==0){
      if(this.navSlideData.header.type =='what-is' && this.navSlideData.dataList.length!==0){
         this.dataSlider.isWhatIs = true;
      }
      if(this.navSlideData.header.type =='news-room' && (this.navSlideData.dataList.length!==0 || this.navSlideData.localDataList.length!==0)){
           this.dataSlider.isNewsRoom = true;  
      }
      if(this.navSlideData.header.type == 'testimonials' && this.navSlideData.dataList.length!==0){
           this.dataSlider.isTestimonial = true;   
      }
      if(this.navSlideData.header.type =='featured-courses' ){ 
       if(this.navSlideData.dataList.length){
         this.dataSlider.isFeatureCourse = true;
       }

      }
      if(this.navSlideData.header.type =='banner-inner' && this.navSlideData.dataList.length!==0){
         this.dataSlider.isBannerInner = true;    
      }
      if(this.navSlideData.header.type =='photo-gallary' && this.navSlideData.galleryList.length!==0){
         this.dataSlider.isPhotoGallery = true;
      }
      if(this.navSlideData.header.type =='video-gallery'){
        if(this.navSlideData.dataList.length){
          this.dataSlider.isVideoGallery = true;
        }

      } 

    }
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.charset = 'utf-8';
    document.getElementById('body').appendChild(script);
  }


  raiseTemeletyInterat(id: string) {
    const event: any = {
      eventType: WsEvents.WsEventType.Telemetry,
      eventLogLevel: WsEvents.WsEventLogLevel.Info,
      data: {
        edata: { type: 'click', id: id},
        object: {id: id, type: 'Course'},
        state: WsEvents.EnumTelemetrySubType.Interact,
        eventSubType: WsEvents.EnumTelemetrySubType.cardContent,
        mode: 'view'
      },
      pageContext: {pageId: '/', module: 'Landing Page'},
      from: '',
      to: 'Telemetry',
    }
    this.eventSvc.dispatchChatbotEvent<WsEvents.IWsEventTelemetryInteract>(event)
  }

  raiseTelemetry(id: string) {
    const event: any = {
      eventType: WsEvents.WsEventType.Telemetry,
      eventLogLevel: WsEvents.WsEventLogLevel.Info,
      data: {
        edata: { type: 'click', id: id, subType: "landing-page-content-list"},
        state: WsEvents.EnumTelemetrySubType.Interact,
        eventSubType: WsEvents.EnumTelemetrySubType.cardContent,
        mode: 'view'
      },
      pageContext: {pageId: '/', module: 'Landing Page'},
      from: '',
      to: 'Telemetry',
    }
    this.eventSvc.dispatchChatbotEvent<WsEvents.IWsEventTelemetryInteract>(event)
  }

  

  openLink(type: string) {
    const link = this.socialLinks.find(ele => ele.name === type);
    if (type === 'twitter') {
      window.open(link.href_url, '_blank');
    }
    else if (type === 'linkedin') {
      window.open(link.href_url, '_blank');
    }
  }

  downloadPdf(url: string) {
    const fileNameSplit = url.split('/');
    const fileName = fileNameSplit[fileNameSplit.length - 1];
    const link = document.createElement('a');
    link.href = url;
    link.target = "_blank";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  openVideo(data: any) {
    if (data.contentDetails) {
      return data.contentDetails.videoId;
    } else if (data.id) {
      return data.id.videoId;
    }

  }

  getCourseUrl(identifier) {
    this.raiseTemeletyInterat(identifier)
    window.open(this.sharedSvc.baseUrl + 'public/toc/' + identifier + '/overview', '_blank') 
  }

  getLinks(url: string) {
    return this.sharedSvc.baseUrl + url
  }

  getYoutubeLink(link: string) {
    const videoId = link.split('=')[1];
    return videoId;
  }

  playWhatIsVideo(video: any) {
    this.currentWhatisActiveVideoid = video.id;
  }

  playVideoGallaryVideo(video: any) {
    // if (typeof (video.id) == 'string') {
    this.currentVideoGallaryPlyerId = video.etag;
    // } else {
    //   this.currentVideoGallaryPlyerId = video.id.videoId;
    // }
  }

  youtubeVideo(link) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.getYoutubeLink(link));
  }

  downloadPDFSuccess(event) {
    setTimeout(() => {
      this.isDownloadComplete = false;
      this.currentDownloadIndex = -1;
      this.toastr.success('Download Successful', '', { timeOut: 2000 })
      // if (this.navSlideData['header']['type'] === 'news-room') {
      //   this.Options = { ...this.Options, autoplay: false, autoplaySpeed: 5000 }
      // }
    }, 1000);
  }

  newsroomDownloadLoader(index) {
    this.isDownloadComplete = true;
    this.currentDownloadIndex = index;
    // if (this.navSlideData['header']['type'] === 'news-room') {
    //   this.Options = { ...this.Options, autoplay: false }
      
    // }
  }

  downloadPDFError(event) {
    this.isDownloadComplete = false;
    this.currentDownloadIndex = -1;
    this.toastr.error('Download Failed, Try again', '', { timeOut: 2000 })
  }

  getTestmonialActiveSlide(slide) {   
    this.activeTestimonialIndex = slide.startPosition 
    this.allTestimonialVideos.forEach((element: ElementRef) => {
      if (element.nativeElement.id !== slide.slides[0].id) {
        element.nativeElement.pause();
      }
    })
  }

  // imageloaded(card: any) {
  //   card['imageLoaded'] = true
  // }

}

