import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentListService } from '../content-list.service';
import { HEADER_DATA, NAV_FOOTER_DETAILS } from '../../shared/constant/app.constant';

@Component({
  selector: 'app-content-list-home',
  templateUrl: './content-list-home.component.html',
  styleUrls: ['./content-list-home.component.scss']
})
export class ContentListHomeComponent implements OnInit {

    configDetails: any
    tableData: any

    headerData = HEADER_DATA;
    slidesList = [
        {
        imgSrc: `./assets/img/1-eng.png`
        },
        {
        imgSrc: `./assets/img/sliderImg1.jpg`
        }, {
        imgSrc: `./assets/img/img2-eng.png`
        }, {
        imgSrc: `./assets/img/English.png`
        }
    ]

    dashboardAnalyticsList = [
        {
        dashboaredHeader: `Number of users/MDO's`,
        analyticsList: [
            {
            imgSrc: `./assets/img/learnsGraph.svg`,
            count: `0`,
            description: `Karmayogis onboarded`,
            alt: `learns record`,
            id: `karmayogiOnboarded`
            }, {
            imgSrc: `./assets/img/learnsGraph.svg`,
            count: `0`,
            description: `Registered MDO's`,
            alt: `learns record`,
            id: `registeredMdo`
            },
        ]
        },
        {
        dashboaredHeader: `Available content`,
        analyticsList: [
            {
            imgSrc: `./assets/img/coursesGraph.svg`,
            count: `0`,
            description: `Courses`,
            alt: `Courses record`,
            id: `courses`
            }, {
            imgSrc: `./assets/img/contentGraph.svg`,
            count: `0`,
            description: `Available content (hours)`,
            alt: `Content record`,
            id: `availableContent`
            },
        ]
        }
    ]

    featuredCourses = {
        header: {
        headerText: `Featured courses`,
        type: ``
        },
        featuredCoursesList: [
        // {
        //   posterImage: `./assets/img/officeprocedure.jpeg`,
        //   name: `Office Procedure`,
        //   description: `Office Procedure`,
        //   identifier: `do_1135948534007070721153`,
        //   alt: `Office Procedure`,
        //   duration: `2h 17m`,
        // }, {
        //   posterImage: `./assets/img/officeprocedure.jpeg`,
        //   name: `Office Procedure`,
        //   description: `Office Procedure`,
        //   identifier: `do_1135948534007070721153`,
        //   alt: `Office Procedure`,
        //   duration: `2h 17m`,
        // }, {
        //   posterImage: `./assets/img/officeprocedure.jpeg`,
        //   name: `Office Procedure`,
        //   description: `Office Procedure`,
        //   identifier: `do_1135948534007070721153`,
        //   alt: `Office Procedure`,
        //   duration: `2h 17m`,
        // }, {
        //   posterImage: `./assets/img/officeprocedure.jpeg`,
        //   name: `Office Procedure`,
        //   description: `Office Procedure`,
        //   identifier: `do_1135948534007070721153`,
        //   alt: `Office Procedure`,
        //   duration: `2h 17m`,
        // }, {
        //   posterImage: `./assets/img/officeprocedure.jpeg`,
        //   name: `Office Procedure`,
        //   description: `Office Procedure`,
        //   identifier: `do_1135948534007070721153`,
        //   alt: `Office Procedure`,
        //   duration: `2h 17m`,
        // },
        ]
    }

    igonVisionDetails = {
        vision: {
        imgUrl: `./assets/img/visionImg.svg`,
        alt: `How does the platform enable you to become the best version of yourself?`,
        },
        videos: [
        {
            poster: `./assets/img/video1.png`,
            videoLink: `./assets/img/Sanjeev-final.mp4`,
            line1: `An `,
            line2: `experienced`,
            line3: ` civil servant`,
        },
        {
            poster: `./assets/img/video2.png`,
            videoLink: `./assets/img/Shilpa-final.mp4`,
            line1: `A `,
            line2: `newly`,
            line3: ` recruited civil servant`,
        },
        ],

    }
    conferanceData = {
        title: 'We are now available on Video Conference',
        description: 'For any Support Required',
        workdays: 'Monday to Friday',
        timings: '9:00 AM to 5:00 PM',
        joinNow:'Join Now'
    }

    registerDetails = {
        lineOne: `Take the`,
        lineTwo: `first step`,
        lineThree: ` towards learning`,
        registerBtn: {
        text: `Register Now`,
        link: `public/signup`
        }
    }


    solutionSpace = {
        solutionSpaceHeader: {
        lineOne: `Solutioning space`,
        lineTwo: `for all of Government`,
        },
        solutionSpacesList: [
        {
            name: `Learning hub`,
            description: `Learn anywhere, anytime and bridge your competency gaps using impactful and engaging learning content.`,
            imgSrc: `./assets/img/school.svg`,
        }, {
            name: `Discussion hub`,
            description: `Discuss and learn with peers, colleagues, civil servants and experts across the country.`,
            imgSrc: `./assets/img/forum.svg`,
        }, {
            name: `Network hub`,
            description: `Connect with civil servants across the country. Grow your network within government circles.`,
            imgSrc: `./assets/img/group.svg`,
        }, {
            name: `Competency hub`,
            description: `Identify your competency requirements, competency gaps, so you can grow faster in the right direction.`,
            imgSrc: `./assets/img/extension.svg`,
        }, {
            name: `Career hub`,
            description: `Explore career opportunities across the country and signal your expertise.`,
            imgSrc: `./assets/img/work.svg`,
        }, {
            name: `Event hub`,
            description: `Enable simultaneous interactive experiential and peer learning.`,
            imgSrc: `./assets/img/event.svg`,
        },
        ]
    }

    quickWalkThroughDetails = {
        videoLink: `./assets/img/KarmayogiBharatWalkthroughNew.mp4`,
        lineOne: `A quick`,
        lineTwo: ` walkthrough of`,
        lineThree: ` the `,
        lineFour: ` Karmayogi Bharat`,
        lineFive: `Portal`,
    }

    mobileAppDownloadDetails = {
        lineOne: `Download`,
        lineTwo: ` iGOT Karmayogi`,
        lineThree: ` mobile app`,
        description: `Continue your lifelong learning experience anywhere anytime.`,
        scanners: [
        {
            link: `https://play.google.com/store/apps/details?id=com.igot.karmayogibharat&pli=1`,
            imgSrc: `./assets/img/Google_Play-Badge-Logo.wine.png`,
            scannerSrc: `./assets/img/scan/qrcode.svg`,
            text: `Scan to download`,
        },
        {
            link: `https://apps.apple.com/in/app/igot-karmayogi/id6443949491`,
            imgSrc: `./assets/img/download-appstore.png`,
            scannerSrc: `./assets/img/scan/iOS_qrcode.svg`,
            text: `Scan to download`,
        },
        ],
        mockupImgSrc: `./assets/img/mobile-latest.png`
    }

    mobileviewAppDownloadDetails = {
        googleStore: {
        imgSrc: `./assets/img/Google_Play-Badge-Logo.wine.png`,
        link: `https://play.google.com/store/apps/details?id=com.igot.karmayogibharat&pli=1`,
        },
        appleStore: {
        imgSrc: `./assets/img/download-appstore.png`,
        link: `https://apps.apple.com/in/app/igot-karmayogi/id6443949491`,
        },
        lineOne: `Download iGOT Karmayogi `,
        lineTwo: `mobile app`
    }

    navFooterDetails = NAV_FOOTER_DETAILS;

    footerDetails = {
        copyRights: 'Copyright © Website managed by Karmayogi Bharat.'
    }
    contentListConfig: any;
    courseData: any = []
    programData: any = []
    assessmentData: any = []
    blendedProgramData: any = []
    curatedProgramData: any = []
    curatedCollectionData: any = []
    showTabs: boolean = false
    currentTab: any;

    constructor(
        private sharedSvc: SharedService,
        private contentListSvc: ContentListService
    ) {
    }

    ngOnInit() {
        this.initialization();
        localStorage.setItem('selectedAppLanguage', 'English' )
        this.getContentListConfig()
    }

    async getContentListConfig() {
        this.contentListConfig = await this.contentListSvc.getContentListConfig().toPromise().catch(_error => {})
        this.getContentData()
    }

    async getContentData() {
        const res = await this.contentListSvc.getContentListData().toPromise().catch(_error => {})
        this.courseData = res.content.filter((v:any) => v.primaryCategory === 'Course')
        this.programData = res.content.filter((v:any) => v.primaryCategory === 'Program')
        this.assessmentData = res.content.filter((v:any) => v.primaryCategory === 'Standalone Assessment')
        this.blendedProgramData = res.content.filter((v:any) => v.primaryCategory === 'Blended Program')
        this.curatedProgramData = res.content.filter((v:any) => v.primaryCategory === 'Curated Program')
        this.curatedCollectionData = res.content.filter((v:any) => v.primaryCategory === 'CuratedCollections')
        if (this.courseData.length === 0) {
            this.contentListConfig.tabs = this.contentListConfig.tabs.filter((v: any) => v.primaryCategory !== 'Course')
        }
        if (this.programData.length === 0) {
            this.contentListConfig.tabs = this.contentListConfig.tabs.filter((v: any) => v.primaryCategory !== 'Program')
        }
        if (this.assessmentData.length === 0) {
            this.contentListConfig.tabs = this.contentListConfig.tabs.filter((v: any) => v.primaryCategory !== 'Standalone Assessment')
        }
        if (this.blendedProgramData.length === 0) {
            this.contentListConfig.tabs = this.contentListConfig.tabs.filter((v: any) => v.primaryCategory !== 'Blended Program')
        }
        if (this.curatedProgramData.length === 0) {
            this.contentListConfig.tabs = this.contentListConfig.tabs.filter((v: any) => v.primaryCategory !== 'Curated Program')
        }
        if (this.curatedCollectionData.length === 0) {
            this.contentListConfig.tabs = this.contentListConfig.tabs.filter((v: any) => v.primaryCategory !== 'CuratedCollections')
        }
        this.tableData = res
        this.currentTab = 'Course'
        switch(this.contentListConfig.tabs[0].primaryCategory) {
            case 'Course':
                this.tableData.content = this.courseData
                break
            case 'Program':
                this.tableData.content = this.programData
                this.tableData.showImg = false
                break
            case 'Standalone Assessment':
                this.tableData.content = this.assessmentData
                break
            case 'Blended Program':
                this.tableData.content = this.blendedProgramData
                break
            case 'Curated Program':
                this.tableData.content = this.curatedProgramData
                break
            case 'CuratedCollections':
            this.tableData.content = this.curatedCollectionData
                break
        }
        this.showTabs = true
    }

    tabCheck($event: any) {
        this.currentTab = $event.heading
        switch($event.heading) {
            case 'Course':
                this.tableData.content = this.courseData
                break
            case 'Program':
                this.tableData.content = this.programData
                break
            case 'Standalone Assessment':
                this.tableData.content = this.assessmentData
                break
            case 'Blended Program':
                this.tableData.content = this.blendedProgramData
                break
            case 'Curated Program':
                this.tableData.content = this.curatedProgramData
                break
            case 'Curated Collections':
                this.tableData.content = this.curatedCollectionData
                break
        }
    }

    initialization() {
        this.getConfigDetails()
    }

    getConfigDetails() {
        if (this.sharedSvc.configDetails) {
            this.configDetails = this.sharedSvc.configDetails;
            this.getCourses()
            this.formateNavLinks()
            this.fromateDashboardAnalyticsList()
            this.conferanceData['meetLink'] = this.configDetails.meetLink
        } else {
        this.sharedSvc.getConfigDetails().subscribe((response: any) => {
            if (response) {
            this.configDetails = response;
            this.getCourses()
            this.formateNavLinks()
            this.fromateDashboardAnalyticsList()
            this.conferanceData['meetLink'] = this.configDetails.meetLink
            }
        })
        }
    }

    getCourses() {
        this.sharedSvc.getcourses()
        .subscribe((response: any) => {
            this.featuredCourses.featuredCoursesList = response
            if(this.featuredCourses && this.featuredCourses.featuredCoursesList && this.featuredCourses.featuredCoursesList.length > 0) {
            this.featuredCourses.featuredCoursesList.forEach(element => {
                if(element.posterImage.includes('https://static.karmayogiprod.nic.in/igotprod')) {
                if(this.configDetails) {
                let arr = element.posterImage.split('igotprod')
                    element.posterImage =  this.configDetails.portalURL + 'content-store' + arr[1]
            }
                }
            })
            }
        })
    }

    fromateDashboardAnalyticsList() {
        if (this.configDetails) {
        this.dashboardAnalyticsList.forEach(dashboardAnlytics => {
            dashboardAnlytics.analyticsList.forEach(analytics => {
            analytics.count = this.configDetails[analytics.id]
            })
        })

        this.dashboardAnalyticsList[0].analyticsList[0].count = this.configDetails.karmayogiOnboarded
        }
    }

    getLinks(url: string) {
        return this.sharedSvc.baseUrl + url
    }

    formateNavLinks() {
        this.navFooterDetails.navLinks[0][2].href = this.configDetails.portalURL + this.navFooterDetails.navLinks[0][2].href;
        this.navFooterDetails.navLinks[1][2].href = this.configDetails.portalURL + this.navFooterDetails.navLinks[1][2].href;
    }

    

}
