import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rank-podium',
  templateUrl: './rank-podium.component.html',
  styleUrls: ['./rank-podium.component.scss']
})
export class RankPodiumComponent implements OnInit {
  @Input() rank1: any;
  @Input() rank2: any;
  @Input() rank3: any;

  sameRankTooltipMsg = `This icon denotes MDOs achieving equal Karma Points. Different ranks may occur because the MDO that earned those points first in the month ranks higher.`
  constructor() { 

  }

  ngOnInit() {

  }

  calculateDivHeight (elem: HTMLElement) {
    // console.log(elem.offsetHeight)
    // console.log(document.querySelector('#rank1top').clientHeight)
    return elem.offsetHeight + 80

  }
}
