import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-mobileapp-download',
  templateUrl: './mobileapp-download.component.html',
  styleUrls: ['./mobileapp-download.component.scss']
})
export class MobileappDownloadComponent implements OnInit {

  @Input() mobileAppDownloadDetails: any

  mobileImgLoaded = false
  scannerImgLoaded = false
  mockupImgLoaded = false

  constructor(public sharedService: SharedService) { }

  ngOnInit() {
  }

}
