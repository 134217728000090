import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-graph',
  templateUrl: './content-graph.component.html',
  styleUrls: ['./content-graph.component.scss']
})
export class ContentGraphComponent implements OnInit {

  @Input() graphDetails: {
    imgSrc: string,
    count: string,
    description: string,
    alt: string,
  }

  constructor() { }

  ngOnInit() {
  }

}
