import { Component, Input, OnInit } from '@angular/core'
import { WsEvents } from '../../../services/events'
import { EventService } from '../../../services/event.service'

import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'ws-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss'],
})
export class SurveyFormComponent implements OnInit {
  surveyPopupData: any
  @Input() surveyPopup: any = {}
  @Input() configData: any = {}
  isSurveyPopup = true
  widgetData: any
  userRead: any
  isTourDone: any
  localStorageFlag: any

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: true,
    dots: false,
    autoHeight: false,
    autoWidth: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      }
    }
  }

  apiData = [
    {
        "albumId": 1,
        "id": 1,
        "title": "accusamus beatae ad facilis cum similique qui sunt",
        "url": "https://via.placeholder.com/600/92c952",
        "thumbnailUrl": "https://via.placeholder.com/150/92c952"
    },
    {
        "albumId": 1,
        "id": 2,
        "title": "reprehenderit est deserunt velit ipsam",
        "url": "https://via.placeholder.com/600/771796",
        "thumbnailUrl": "https://via.placeholder.com/150/771796"
    }
  ]
  constructor(
    private eventSvc: EventService
  ) { }

  ngOnInit() {
    const localFlag = localStorage.getItem('surveyPopup')
    if (localFlag === 'true') {
      this.localStorageFlag = true
    } else if (localFlag === 'false') {
      this.localStorageFlag = false
    }
  }

  closeCard() {
    this.isSurveyPopup = false
    if (this.localStorageFlag) {
      localStorage.setItem('surveyPopup', 'false')
      this.localStorageFlag = false
    }
  }

  raiseTemeletyInterat() {
    const event: any = {
      eventType: WsEvents.WsEventType.Telemetry,
      eventLogLevel: WsEvents.WsEventLogLevel.Info,
      data: {
        edata: { type: 'click', id: "take-survey-popup",  subType:"anonymous-assessment"},
        state: WsEvents.EnumTelemetrySubType.Interact,
        eventSubType: WsEvents.EnumTelemetrySubType.cardContent,
        mode: 'view'
      },
      pageContext: {pageId: '/', module: 'Landing Page'},
      from: '',
      to: 'Telemetry',
    }
    this.eventSvc.dispatchChatbotEvent<WsEvents.IWsEventTelemetryInteract>(event)
    window.location.href = this.surveyPopup.surveyUrl
  }


  raiseTemeletyEnd() {
    const event:any = {
      eventType: WsEvents.WsEventType.Telemetry,
      eventLogLevel: WsEvents.WsEventLogLevel.Info,
      data: {
        edata: { type: 'click', id: "take-survey-popup",  subType:"anonymous-assessment"},
        state: WsEvents.EnumTelemetrySubType.Unloaded,
        eventSubType: WsEvents.EnumTelemetrySubType.cardContent,
        mode: 'view',
      },
      pageContext: {pageId: "/", module: "Landing Page"},
      from: '',
      to: 'Telemetry',
    }
    this.eventSvc.dispatchChatbotEvent<WsEvents.IWsEventTelemetryInteract>(event)
  }

  raiseTemeletyStart() {
    const event:any = {
      eventType: WsEvents.WsEventType.Telemetry,
      eventLogLevel: WsEvents.WsEventLogLevel.Info,
      data: {
        edata: { type: 'click', id: "take-survey-popup",  subType:"anonymous-assessment"},
        state: WsEvents.EnumTelemetrySubType.Loaded,
        eventSubType: WsEvents.EnumTelemetrySubType.cardContent,
        type: 'app',
        mode: 'view',
      },
      pageContext: {pageId: "/", module: "Landing Page"},
      from: '',
      to: 'Telemetry',
    }
    this.eventSvc.dispatchChatbotEvent<WsEvents.IWsEventTelemetryInteract>(event)
  }


  takeAction(data: any) {
    let baseUrl = this.configData.portalURL
    if(data && data.surveyType === 'content') {      
      this.raiseTemeletyStart()
      this.raiseTemeletyInterat()
      this.raiseTemeletyEnd()
      window.location.href = `${baseUrl}public/toc/${data.surveyUrl}/overview`
    }
  }
}

