import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared/shared.module';
import { ContentListRoutingModule } from './content-list-routing.module';
import { ContentListHomeComponent } from './content-list-home/content-list-home.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ContentListService } from './content-list.service';
import { CommoncomponentsModule } from '../shared/shared/commoncomponents/commoncomponents.module';


@NgModule({
  declarations: [ContentListHomeComponent],
  imports: [
    CommonModule,
    SharedModule,
    CommoncomponentsModule,
    ContentListRoutingModule,
    TabsModule.forRoot()
  ],
  providers: [ContentListService]
})
export class ContentListModule { }
