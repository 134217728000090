import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators'
// import configuration from '../../../../assets/jsonfiles/configurations.json'

const API_END_POINTS = {
  FETCH_CONTENT_TAB: 'assets/jsonfiles/content-list-tab.json',
  FETCH_CONTENT_DATA: 'assets/jsonfiles/content-list-data.json'
}

@Injectable({
  providedIn: 'root'
})

export class ContentListService {

  baseUrl: string
  configDetails: any

  constructor(
    private http: HttpClient
  ) { }

  getContentListConfig():Observable<any> {
    return this.http.get<any>(`${API_END_POINTS.FETCH_CONTENT_TAB}`)
  }

  getContentListData():Observable<any> {
    return this.http.get<any>(`${API_END_POINTS.FETCH_CONTENT_DATA}`)
  }

  getCourse
}