import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { MdoUserListService } from '../mdo-user-list.service';
import { CONFERENCE_DATA, DASHBOARD_ANALYTICS_LIST, FEATURES_COURSES, FOOTER_DETAILS, HEADER_DATA, IGON_VISION_DETAILS, MOBILE_APP_DOWNLOADS_DETAILS, MOBILE_VIEW_APP_DOWNLOADS_DETAILS, NAV_FOOTER_DETAILS, QUICK_WALKTHROUGH_DETAILS, REGISTER_DETAILS, SOLUTIONS_SPACE } from '../../shared/constant/app.constant';

@Component({
  selector: 'app-mdo-user-list-home',
  templateUrl: './mdo-user-list-home.component.html',
  styleUrls: ['./mdo-user-list-home.component.scss']
})
export class MdoUserListHomeComponent implements OnInit {

    configDetails: any
    tableData: any

    headerData = HEADER_DATA;
    dashboardAnalyticsList = DASHBOARD_ANALYTICS_LIST;
    featuredCourses = FEATURES_COURSES;
    igonVisionDetails = IGON_VISION_DETAILS;
    conferanceData = CONFERENCE_DATA;
    registerDetails = REGISTER_DETAILS;
    solutionSpace = SOLUTIONS_SPACE;
    quickWalkThroughDetails = QUICK_WALKTHROUGH_DETAILS;
    mobileAppDownloadDetails = MOBILE_APP_DOWNLOADS_DETAILS;
    mobileviewAppDownloadDetails =MOBILE_VIEW_APP_DOWNLOADS_DETAILS;
    navFooterDetails = NAV_FOOTER_DETAILS;
    footerDetails = FOOTER_DETAILS;

    constructor(
        private sharedSvc: SharedService,
        private mdoUserListSvc: MdoUserListService
    ) {
    }

    ngOnInit() {
        this.initialization();
        localStorage.setItem('selectedAppLanguage', 'English' )
        this.getMdoUserList()
    }

    async getMdoUserList() {
        this.tableData = await this.mdoUserListSvc.getMdoUserList().toPromise().catch(_error => {})
    }

    initialization() {
        this.getConfigDetails()
    }

    getConfigDetails() {
        if (this.sharedSvc.configDetails) {
            this.configDetails = this.sharedSvc.configDetails;
            this.getCourses()
            this.formateNavLinks()
            this.fromateDashboardAnalyticsList()
            this.conferanceData['meetLink'] = this.configDetails.meetLink
        } else {
        this.sharedSvc.getConfigDetails().subscribe((response: any) => {
            if (response) {
            this.configDetails = response;
            this.getCourses()
            this.formateNavLinks()
            this.fromateDashboardAnalyticsList()
            this.conferanceData['meetLink'] = this.configDetails.meetLink
            }
        })
        }
    }

    getCourses() {
        this.sharedSvc.getcourses()
        .subscribe((response: any) => {
            this.featuredCourses.dataList = response
            if(this.featuredCourses && this.featuredCourses.dataList && this.featuredCourses.dataList.length > 0) {
            this.featuredCourses.dataList.forEach(element => {
                if(element.posterImage.includes('https://static.karmayogiprod.nic.in/igotprod')) {
                if(this.configDetails) {
                let arr = element.posterImage.split('igotprod')
                    element.posterImage =  this.configDetails.portalURL + 'content-store' + arr[1]
            }
                }
            })
            }
        })
    }

    fromateDashboardAnalyticsList() {
        if (this.configDetails) {
        this.dashboardAnalyticsList.forEach(dashboardAnlytics => {
            dashboardAnlytics.analyticsList.forEach(analytics => {
            analytics.count = this.configDetails[analytics.id]
            })
        })

        this.dashboardAnalyticsList[0].analyticsList[0].count = this.configDetails.karmayogiOnboarded
        }
    }

    getLinks(url: string) {
        return this.sharedSvc.baseUrl + url
    }

    formateNavLinks() {
        // this.navFooterDetails.navLinks[0][2].href = this.configDetails.portalURL + this.navFooterDetails.navLinks[0][2].href;
        // this.navFooterDetails.navLinks[1][2].href = this.configDetails.portalURL + this.navFooterDetails.navLinks[1][2].href;
    }

}
