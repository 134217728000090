import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicHomeRoutingModule } from './public-home-routing.module';
import { PublicHomeComponent } from './components/public-home/public-home.component';
import { PublicHomeHindiComponent } from './components/public-home-hindi/public-home-hindi.component';
import { SharedModule } from '../shared/shared/shared.module';
import { LatestUpdatesComponent } from './components/latest-updates/latest-updates.component';
import { CommoncomponentsModule } from '../shared/shared/commoncomponents/commoncomponents.module';



@NgModule({
  declarations: [
    PublicHomeComponent,
    PublicHomeHindiComponent,
    LatestUpdatesComponent
  ],
  imports: [
    CommonModule, 
    SharedModule,
    CommoncomponentsModule,
    PublicHomeRoutingModule,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class PublicHomeModule { }
