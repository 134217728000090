import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { MOBILE_VIEW_APP_DOWNLOADS_DETAILS, NAV_FOOTER_DETAILS, NAV_FOOTER_DETAILS_MOBILE } from '../../constant/app.constant';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {

  @Input() navFooterDetails: any = {}
  navfooterDetail :any = NAV_FOOTER_DETAILS
  linksData: any[] = [
    { active: true, href_url: "https://twitter.com/iGOTKarmayogi", name: "twitter", src: "assets/social_icons/x-f.svg" },
    { active: true, href_url: "https://www.linkedin.com/company/karmayogi-bharat/", name: "linkedin", src: "assets/social_icons/in-f.svg" },
    { active: true, href_url: "https://www.youtube.com/channel/UCPO2faT8YEi6Q_2IY5kf2Dg", name: "youtube", src: "assets/social_icons/yt-f.svg" },
    { active: true, href_url: "https://www.instagram.com/karmayogibharat/", name: "instagram", src: "assets/social_icons/inst-f.svg" },
    { active: true, href_url: "https://www.facebook.com/profile.php?id=100089782863897", name: "facebook", src: "assets/social_icons/fb-f.svg" },
  ];
  mobileviewAppDownloadDetails = MOBILE_VIEW_APP_DOWNLOADS_DETAILS;
  footerSectionConfig = [
    {
      "id":1,
      "order":1,
      "sectionHeading": "Hubs",
      "active": true,
      "slug": "hub"
    },
    {
      "id":2,
      "order":2,
      "sectionHeading": "Related Links",
      "active": true,
      "slug": "link"
    },
    {
      "id":3,
      "order":3,
      "sectionHeading": "Support",
      "active": true,
      "slug": "support"
    },
    {
      "id":4,
      "order":4,
      "sectionHeading": "About us",
      "active": true,
      "slug": "about"
    }
  ];

  footerNavMobile = NAV_FOOTER_DETAILS_MOBILE;

  constructor(private router: Router, public sharedService: SharedService
  ) { }

  ngOnInit() {
  }

  routeNavigation(route: string, fragment: string) {
    this.router.navigate([route], { fragment: fragment })
  }
  onClick(event: any) {
    // console.log(event.target.parentElement);
    event.target.parentElement.classList.toggle('open')
  }

}
