import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  PageChangedEvent
} from 'ngx-bootstrap/pagination';
import { PipeDurationTransformPipe } from '../pipes/pipe-duration-transform/pipe-duration-transform.pipe';

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
  providers:[PipeDurationTransformPipe]
})
export class TableListComponent implements OnInit {

  @Input() tableData: any
  @Input() tableType: any
  contentArray = []
  returnedArray ? : string[];
  listLimit: number = 10
  totalCount: number = 0
  searchText: string = ''
  filteredData = []
  tableHeaders: any = {admin_email_head : "", admin_head: "", ministry_head: "", org_head: ''  };
  constructor(
  ) {}

  ngOnInit() {
    if (this.tableType === 'content') {
      this.getContentListItems()
    }else if (this.tableType === 'mdo') {
      this.tableData = {
        displayColumn: Object.keys(this.tableData.headerInfo),
        ...this.tableData
      }
      if(this.tableData.headerInfo.admin_email_head === 'Nodal Email') {
        this.tableHeaders.admin_email_head = 'nodelHeader.nodalEmail'
      }
      
      if(this.tableData.headerInfo.admin_head === 'Nodal Name') {
        this.tableHeaders.admin_head = 'nodelHeader.nodalName'
      }
      if(this.tableData.headerInfo.ministry_head === 'Ministry') {
        this.tableHeaders.ministry_head = 'nodelHeader.ministry'
      }
      if(this.tableData.headerInfo.org_head === 'Organization') {
        this.tableHeaders.org_head = 'nodelHeader.organization'
      }

      this.getContentListItems()
    }
  }

  getClass(item:any) {
    return (item === 'description') ? 'descriptions' : item
  }

  async getContentListItems() {
    this.totalCount = (this.tableData) ? this.tableData.content.length : 0
    this.contentArray = (this.tableData) ? this.tableData.content : []
    this.returnedArray = this.contentArray.slice(0, this.listLimit);
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.contentArray.slice(startItem, endItem);
  }

  changeToDefaultImg($event: any) { 
    $event.target.src = `/assets/img/default.png`
  }

  filterByText() {
    if (this.searchText) {
      this.filteredData = this.contentArray.filter((v:any) => {
        if (this.tableType === 'content') {
          if (v.name && v.name.toLowerCase().includes(this.searchText.toLowerCase())) {
            return true
          }
          if (v.source instanceof Array) {
            v.source = v.source.toString()
          }
          if (v.source && v.source.toLowerCase().includes(this.searchText.toLowerCase())) {
            return true
          }
          if (v.description && v.description.toLowerCase().includes(this.searchText.toLowerCase())) {
            return true
          }
        } else if (this.tableType === 'mdo') {
          if (Object.keys(v).includes(this.tableData.headerInfo['org_head']) && v[this.tableData.headerInfo['org_head']].toLowerCase().includes(this.searchText.toLowerCase())) {
            return true
          }
          if (Object.keys(v).includes(this.tableData.headerInfo['ministry_head']) && v[this.tableData.headerInfo['ministry_head']].toLowerCase().includes(this.searchText.toLowerCase())) {
            return true
          }
          if (Object.keys(v).includes(this.tableData.headerInfo['admin_head']) && v[this.tableData.headerInfo['admin_head']].toLowerCase().includes(this.searchText.toLowerCase())) {
            return true
          }
          if (Object.keys(v).includes(this.tableData.headerInfo['admin_email_head']) && v[this.tableData.headerInfo['admin_email_head']].toLowerCase().includes(this.searchText.toLowerCase())) {
            return true
          }
        }
      })
      this.totalCount = (this.filteredData) ? this.filteredData.length : 0
      this.returnedArray = this.filteredData.slice(0, this.listLimit);
    } else {
      this.getContentListItems()
    }
  }

}
