import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-photogallery',
  templateUrl: './photogallery.component.html',
  styleUrls: ['./photogallery.component.scss'],

})
export class PhotogalleryComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() photosGallery: any;
  intervalId: any;

  scrollInterval: any;
  scrollSpeed: number = 5000;

  @ViewChild('galleryElement', { static: false }) galleryElement!: ElementRef;

  private isDown: boolean = false;
  private startX: number = 0;
  private scrollLeft: number = 0;
  
  constructor() { }


  ngOnInit() { }

  ngAfterViewInit() {
    // this.startScrolling();
    // this.setupSliderEventListeners()
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.stopScrolling();
    // this.removeSliderEventListeners();
  }

  startScrolling() {
    this.scrollInterval = setInterval(() => {
      this.scrollRight();
    }, this.scrollSpeed);
  }

  stopScrolling() {
    clearInterval(this.scrollInterval);
  }

  scrollRight() {
    const container = document.getElementById('gallaryElement')
    container.scrollLeft += 400;

    if (Math.ceil(container.scrollLeft) >= (container.scrollWidth - container.offsetWidth)) {
      container.scrollTo({ left: 0, behavior: 'auto' });
    }
  }

  setupSliderEventListeners() {
    const slider =  document.getElementById('gallaryElement')
    slider.addEventListener('mousedown', (event) => {
      this.isDown = true;
      slider.classList.add('active');
      this.startX = event.pageX - slider.offsetLeft;
      this.scrollLeft = slider.scrollLeft;
      this.stopScrolling();
    });

    slider.addEventListener('mouseleave', () => {
      this.isDown = false;
      slider.classList.remove('active');
      this.startScrolling();
    });

    slider.addEventListener('mouseup', () => {
      this.isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mousemove', (event) => {
      if (!this.isDown) return;
      event.preventDefault();
      const x = event.pageX - slider.offsetLeft;
      const walk = (x - this.startX) * 3;
      slider.scrollLeft = this.scrollLeft - walk;
    });
  }

  removeSliderEventListeners() {
    const slider = this.galleryElement.nativeElement;

    slider.removeEventListener('mousedown');
    slider.removeEventListener('mouseleave');
    slider.removeEventListener('mouseup');
    slider.removeEventListener('mousemove');
  }

}
