import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-solution-space',
  templateUrl: './solution-space.component.html',
  styleUrls: ['./solution-space.component.scss']
})
export class SolutionSpaceComponent implements OnInit {

  @Input() solutionSpace: any

  constructor() { }

  ngOnInit() {
  }

}
