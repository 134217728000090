import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicHomeComponent } from './components/public-home/public-home.component';
import { PublicHomeHindiComponent } from './components/public-home-hindi/public-home-hindi.component';
import { LatestUpdatesComponent } from './components/latest-updates/latest-updates.component';


const routes: Routes = [
  {
    path: '',
    component: PublicHomeComponent,
  },
  {
    path: 'hindi',
    component: PublicHomeHindiComponent,
  },
  {
    path: 'latest-updates',
    component: LatestUpdatesComponent,
  },
  {
    path: 'latest-updates-hi',
    component: LatestUpdatesComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class PublicHomeRoutingModule { }
