import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-analytics',
  templateUrl: './dashboard-analytics.component.html',
  styleUrls: ['./dashboard-analytics.component.scss']
})
export class DashboardAnalyticsComponent implements OnInit {

  @Input() dashboardAnalyticsList = []

  constructor() { }

  ngOnInit() {
  }

}
