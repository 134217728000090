import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-circular-divs',
  templateUrl: './circular-divs.component.html',
  styleUrls: ['./circular-divs.component.scss']
})
export class CircularDivsComponent implements OnInit {
  showInfo: boolean = false;
  infoHeading: string = '';
  infoDetails: string = '';
  infoImage: string = '';
  selectedCategory: string = 'Learn'; // Initialize with the value of the first category
  categories: string[] = ['Learn', 'Events', 'Network', 'Discuss' , 'Competencies','Careers'];
  currentIndex: number = 0;

  ngOnInit() {
    this.showInfoDetails(this.selectedCategory); // Call showInfoDetails with the initial category value
  
   //Start the carousel effect
  interval(15000).subscribe(() => {
    this.currentIndex = (this.currentIndex + 1) % this.categories.length;
    this.showInfoDetails(this.categories[this.currentIndex]);
  });

  }
  
  showInfoDetails(category: string) {
    this.showInfo = true;
    this.selectedCategory = category; // Update the selected category
    switch(category) {
      case 'Learn':
        this.infoHeading = 'hubNames.learnHub';
        this.infoDetails = 'solutionSpace.learnHub';
        this.infoImage = './assets/karmayogi-hubs/Learn-product.svg';
        this.currentIndex =0;
        break;
      case 'Events':
        this.infoHeading = 'hubNames.eventsHub';
        this.infoDetails = 'solutionSpace.eventsHub';
        this.infoImage = './assets/karmayogi-hubs/events.svg';
        this.currentIndex =1;
        break;
      case 'Network':
        this.infoHeading = 'hubNames.networkHub';
        this.infoDetails = 'solutionSpace.networkHub';
        this.infoImage = './assets/karmayogi-hubs/Network.svg';
        this.currentIndex =2;
        break;
      case 'Discuss':
        this.infoHeading = 'hubNames.discussHub';
        this.infoDetails = 'solutionSpace.discussHub';
        this.infoImage = './assets/karmayogi-hubs/Discuss.svg';
        this.currentIndex =3;
        break;
      case 'Competencies':
        this.infoHeading = 'hubNames.careerHub';
        this.infoDetails = 'solutionSpace.competencyHub';
        this.infoImage = './assets/karmayogi-hubs/Competencies.svg';
        this.currentIndex =4;
        break;
      case 'Careers':
        this.infoHeading = 'hubNames.competencyHub';
        this.infoDetails = 'solutionSpace.careerHub';
        this.infoImage = './assets/karmayogi-hubs/Careers.svg';
        this.currentIndex =5;
        break;
      default:
        break;
    }
  }

  navigateToSlide(index: number) {
    this.currentIndex = index;
    console.log(this.currentIndex,this.categories[this.currentIndex] )
    this.showInfoDetails(this.categories[this.currentIndex]);
  }
  
}
