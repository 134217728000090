import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import Typed, { TypedOptions } from "typed.js";


@Component({
  selector: 'app-typing-animation',
  template: `<div #wrapper style="height: 61px;">
		<ng-content></ng-content>
	</div>`,
  styleUrls: ['./typing-animation.component.scss']
})
export class TypingAnimationComponent implements AfterViewInit, OnChanges {

  @Input() public getOptions: any;

	private typed: Typed;

	ngAfterViewInit(): void {
		this.typed = new Typed(document.querySelector(".typing"), this.options);
	}

	public toggle(): void {
		this.typed.toggle();
	}

	public stop(): void {
		this.typed.stop();
	}

	public start(): void {
		this.typed.start();
	}

	public destroy(): void {
		this.typed.destroy();
	}

	public doReset(restart?: boolean): void {
		this.typed.reset(restart);
	}

	private get options(): TypedOptions {
		const opts = this.getOptions as TypedOptions;

		return opts;
	}

	ngOnChanges(): void {
		if (this.typed) {
			this.typed.destroy();
			this.ngAfterViewInit();
		}
	}

}
