import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultilingualTranslationService {
  currentLanguage = 'en';
  languages: string[] = ['en', 'hi']
  private languageChange = new BehaviorSubject<string>('');
  public languageChange$ = this.languageChange.asObservable();
  constructor(private translate: TranslateService) { }

  initializeLanguage() {
    const language = localStorage.getItem('lang');
    if (language) {
      this.currentLanguage = language;
      this.translate.addLangs(this.languages);
      this.translate.setDefaultLang(this.currentLanguage);
      this.languageChange.next(this.currentLanguage);
    } else {
      this.translate.addLangs(this.languages);
      this.translate.setDefaultLang(this.currentLanguage);
      localStorage.setItem('lang', this.currentLanguage);
      this.languageChange.next(this.currentLanguage);
    }
  }

  setlanguageChange(lang: string) {
    this.languageChange.next(lang);
  }
}
