import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { WsEvents } from './events'
import { HttpClient } from '@angular/common/http'
import { ChatbotService } from 'src/app/components/app-chatbot/chatbot.service'

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private eventsChatbotSubject = new Subject<WsEvents.IWsEvents<any>>()
  public chatbotEvents$ = this.eventsChatbotSubject.asObservable()

  dispatchChatbotEvent<T>(event: WsEvents.IWsEvents<T>) {
    console.log("event ", event)
    this.eventsChatbotSubject.next(event)
  }
}
