import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-topproviders',
  templateUrl: './topproviders.component.html',
  styleUrls: ['./topproviders.component.scss']
})
export class TopprovidersComponent implements OnInit, OnDestroy {
  @Input() topProviders: any;
  intervalId: any;
  constructor() { }

  ngOnInit() {
    this.startInterval();
  }
  
  next(): void { this.topProviders.topProvidersList.unshift(this.topProviders.topProvidersList.pop()); }
  previous(): void { this.topProviders.topProvidersList.push(this.topProviders.topProvidersList.shift()); }

  startInterval() {
    this.intervalId = setInterval(() => {
      this.next();
    }, 5000); 
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }


}
