import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
declare var Plyr: any;

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent implements OnInit, AfterViewInit {
  @Input('playerId') playerId!: string;
  @Input('videoUrl') videoUrl: any;
  @Input('videoId') videoId!: string | null;
  @Input('placeholderImage') placeholderImage!: string;
  @Input('title') title!: string;
  @Input('use') use: string = 'player';

  public player!: any;
  options: any = {
    controls: ['play-large', 'play', 'progress', 'current-time', 'fullscreen'],
    fullscreen: {
      enabled: true,
      iosNative: true,
      container: '#video-container',
    },
    volume: 1,
    title: false
  };
  youtubeLink: string | SafeUrl;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.initializeIframeVideo();
  }


  ngAfterViewInit(): void {
    this.player = new Plyr('#' + this.playerId, this.options);
    if (this.placeholderImage) {
      this.player.poster = this.placeholderImage;
    }
    this.player.on('ready', (event: any) => {
      this.player.play();
    });

  }

  initializeIframeVideo() {
    const youtubeLink = 'https://www.youtube.com/embed/' + this.videoId + '?autoplay=1'
    this.youtubeLink = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeLink);
  }

}
