import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit {
  @Input() statData: any[] = [];
  isSkeletonLoader:boolean = true;
  constructor() { }

  ngOnInit() {
      if(this.statData.length){
        this.isSkeletonLoader =  false;
    }
  }

}
