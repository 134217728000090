import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-videoconference-maintain',
  templateUrl: './videoconference-maintain.component.html',
  styleUrls: ['./videoconference-maintain.component.scss']
})
export class VideoconferenceMaintainComponent implements OnInit {
  @Input() videoConf: any;
  constructor() { }

  ngOnInit() {
  }

  joinVideoConference(joinLink: string) {
    window.open(joinLink, '_blank')

  }
}
