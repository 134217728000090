import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared/shared.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MdoUserListHomeComponent } from './mdo-user-list-home/mdo-user-list-home.component';
import { MdoUserListRoutingModule } from './mdo-user-list-routing.module';
import { MdoUserListService } from './mdo-user-list.service';
import { CommoncomponentsModule } from '../shared/shared/commoncomponents/commoncomponents.module';


@NgModule({
  declarations: [MdoUserListHomeComponent],
  imports: [
    CommonModule,
    SharedModule,
    CommoncomponentsModule,
    MdoUserListRoutingModule,
    TabsModule.forRoot()
  ],
  providers: [MdoUserListService]
})
export class MdoUserListModule { }
