import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MdoUserListHomeComponent } from './mdo-user-list-home/mdo-user-list-home.component';


const routes: Routes = [
  {
    path: '',
    component: MdoUserListHomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MdoUserListRoutingModule { }
