import { Component } from '@angular/core';
import { TelemetryService } from './modules/shared/services/telemetry.service';
import { MultilingualTranslationService } from './modules/shared/services/multilingual-translation.service';
import { HEADER_DATA } from './modules/shared/constant/app.constant';
import { WsEvents } from './modules/shared/services/events';
import { EventService } from './modules/shared/services/event.service';
import { SharedService } from './modules/shared/services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  headerData = HEADER_DATA;
  title = 'sunbird-cb-staticweb';
  isMaintenancePage: any
  constructor(private telemetryService: TelemetryService,
    private multilingualService: MultilingualTranslationService,
    private eventSvc: EventService, 
    public sharedService: SharedService) {
    
    this.isMaintenancePage = window.location.href.includes('/maintenance')
    this.multilingualService.initializeLanguage();
    this.telemetryService.getConfigDetails().subscribe((data) => {
      this.raiseTemeletyStart()
    })

    // this.sharedService.getConfigDetails().subscribe((data) => {
    //   this.isMaintenancePage = data.isMaintenancePage
    //   window.location.href = "/#/maintenance"
    // })
  }

  raiseTemeletyStart() {
    const event:any = {
      eventType: WsEvents.WsEventType.Telemetry,
      eventLogLevel: WsEvents.WsEventLogLevel.Info,
      data: {
        edata: { type: '' },
        object: {type: "", id: ""},
        state: WsEvents.EnumTelemetrySubType.Loaded,
        eventSubType: WsEvents.EnumTelemetrySubType.cardContent,
        type: 'app',
        mode: 'view',
      },
      pageContext: {pageId: "/", module: "Landing Page"},
      from: '',
      to: 'Telemetry',
    }
    this.eventSvc.dispatchChatbotEvent<WsEvents.IWsEventTelemetryInteract>(event)
  }
}
