import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-igot-vision',
  templateUrl: './igot-vision.component.html',
  styleUrls: ['./igot-vision.component.scss']
})
export class IgotVisionComponent implements OnInit {

  @Input() igonVisionDetails: any;

  constructor() { }

  ngOnInit() {
  }

}
