import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from '../components/slider/slider.component';
import { ContentGraphComponent } from '../components/content-graph/content-graph.component';
import { DashboardAnalyticsComponent } from '../components/dashboard-analytics/dashboard-analytics.component';
import { RegisterComponent } from '../components/register/register.component';
import { SolutionSpaceComponent } from '../components/solution-space/solution-space.component';
import { QuickWalkThroughComponent } from '../components/quick-walk-through/quick-walk-through.component';
import { VisionComponent } from '../components/vision/vision.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { IgotVisionComponent } from '../components/igot-vision/igot-vision.component';
import { VideoConferenceComponent } from '../components/video-conference/video-conference.component';
import { RouterModule } from '@angular/router';
import { TableListComponent } from '../components/table-list/table-list.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PipeDurationTransformModule } from '../components/pipes/pipe-duration-transform/pipe-duration-transform.module';
import { FormsModule } from '@angular/forms';
import { HallOfFameComponent } from '../components/hall-of-fame/hall-of-fame.component';
import { RankPodiumComponent } from '../components/rank-podium/rank-podium.component';
import { PipeFilterByProp } from '../pipes/filter-by-prop.pipe';
import { SkeletonLoaderModule } from '../components/skeleton-loader/skeleton-loader.module';
import { PopoverConfig, PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipeFilterRoundInteger } from '../pipes/round-integer.pipe';
import { InfocusslideComponent } from '../components/infocusslide/infocusslide.component';
import { TopprovidersComponent } from '../components/topproviders/topproviders.component';
import { AboutusComponent } from '../components/aboutus/aboutus.component';
import { PhotogalleryComponent } from '../components/photogallery/photogallery.component';
import { NavSliderComponent } from '../components/nav-slider/nav-slider.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { StatComponent } from '../components/stat/stat.component';
import { ContentComponent } from '../components/content/content.component';
import { TypingAnimationComponent } from '../components/typing-animation/typing-animation.component';
import { TranslateModule } from '@ngx-translate/core';
import { CircularDivsComponent } from '../components/circular-divs/circular-divs.component';
import { SocialHubComponent } from '../components/social-hub/social-hub.component';
import { FileSaverModule } from 'ngx-filesaver';
import { YoutubePlayerComponent } from '../components/youtube-player/youtube-player.component';
import { PipePublicURLModule } from './../../../pipe-public-URL/pipe-public-URL.module';
import { TestimonialSliderComponent } from '../components/testimonial-slider/testimonial-slider.component';
import { CommoncomponentsModule } from './commoncomponents/commoncomponents.module';
import { VideoconferenceMaintainComponent } from '../components/videoconference-maintain/videoconference-maintain.component';
export function getPopoverConfig(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {
    placement: 'auto',
    // container: 'body',
    // triggers: 'focus'
  });
}



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    ContentGraphComponent,
    DashboardAnalyticsComponent,
    RegisterComponent,
    SolutionSpaceComponent,
    QuickWalkThroughComponent,
    VisionComponent,
    IgotVisionComponent,
    VideoConferenceComponent,
    VideoconferenceMaintainComponent,
    TableListComponent,
    HallOfFameComponent,
    RankPodiumComponent,
    PipeFilterByProp,
    PipeFilterRoundInteger,
    InfocusslideComponent,
    TopprovidersComponent,
    AboutusComponent,
    PhotogalleryComponent,
    NavSliderComponent,
    NavbarComponent,
    StatComponent,
    ContentComponent,
    TypingAnimationComponent,
    CircularDivsComponent,
    SocialHubComponent,
    YoutubePlayerComponent,
    TestimonialSliderComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule,
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    PipeDurationTransformModule,
    FormsModule,
    SkeletonLoaderModule,
    TranslateModule,
    FileSaverModule,
    PipePublicURLModule,
    CommoncomponentsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    ContentGraphComponent,
    DashboardAnalyticsComponent,
    RegisterComponent,
    SolutionSpaceComponent,
    QuickWalkThroughComponent,
    VisionComponent,
    IgotVisionComponent,
    VideoConferenceComponent,
    VideoconferenceMaintainComponent,
    TableListComponent,
    HallOfFameComponent,
    RankPodiumComponent,
    PipeFilterByProp,
    PipeFilterRoundInteger,
    SkeletonLoaderModule,
    InfocusslideComponent,
    TopprovidersComponent,
    AboutusComponent,
    PhotogalleryComponent,
    NavSliderComponent,
    NavbarComponent,
    StatComponent,
    ContentComponent,
    TypingAnimationComponent,
    TranslateModule,
    CircularDivsComponent,
    SocialHubComponent,
    YoutubePlayerComponent,
    PipePublicURLModule,
    FileSaverModule,

  ],
  providers: [
    { provide: PopoverConfig, useFactory: getPopoverConfig }
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
