import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sociallinks',
  templateUrl: './sociallinks.component.html',
  styleUrls: ['./sociallinks.component.scss']
})
export class SociallinksComponent implements OnInit {
  @Input() linksData: any[] = [];
  constructor() { }

  ngOnInit() {
  }

}
