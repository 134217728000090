import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-conference',
  templateUrl: './video-conference.component.html',
  styleUrls: ['./video-conference.component.scss']
})
export class VideoConferenceComponent implements OnInit {

  @Input() conferanceData: any;
  constructor() { }

  ngOnInit() {
    //console.log(this.conferanceData,'conferanceData')
  }

}
