import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators'
// import configuration from '../../../../assets/jsonfiles/configurations.json'

const API_END_POINTS = {
  FETCH_MDO_USER: 'assets/jsonfiles/mdo-admin-details.json',
}

@Injectable({
  providedIn: 'root'
})

export class MdoUserListService {

  baseUrl: string
  configDetails: any

  constructor(
    private http: HttpClient
  ) { }

  getMdoUserList():Observable<any> {
    return this.http.get<any>(`${API_END_POINTS.FETCH_MDO_USER}`)
  }
}