import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @Input() registerDetails: any

  constructor(
    private sharedSvc: SharedService
  ) { }

  ngOnInit() {
  }

  getLinks(url: string) {
    return this.sharedSvc.baseUrl + url
  }

}
