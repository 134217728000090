import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {

  LANGUAGES ='api/faq/v1/assistant/available/language'
  CONFIG = 'api/faq/v1/assistant/configs/language'

  constructor(private http: HttpClient){}


  getConfiguration(): Observable<any> {
    return this.http.get<any>('assets/jsonfiles/configurations.json');
  }

  getLangugages(): Observable<any> {
    return this.getConfiguration().pipe(
      switchMap( response => {
        const requiredData = response;
        return this.http.get<any>(requiredData.telmetryUrl + this.LANGUAGES);
      })
    );
  }

  getChatData(tabType:any): any {
    return this.getConfiguration().pipe(
      switchMap( response => {
        const requiredData = response;
        return this.http.post<any>(`${requiredData.telmetryUrl}${this.CONFIG}`,tabType)

      })
    );
  }
}




