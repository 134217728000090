import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-videoconference',
  templateUrl: './videoconference.component.html',
  styleUrls: ['./videoconference.component.scss']
})
export class VideoconferenceComponent implements OnInit {
  @Input() videoConf: any;
  constructor() { }

  ngOnInit() {
  }

  joinVideoConference(joinLink: string) {
    window.open(joinLink, '_blank')

  }
}
